import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

/* import main Tailwind css */
import './index.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faPlay, faPause, faForward, faVolumeMute, faVolumeUp, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faPlay, faPause, faForward, faVolumeMute, faVolumeUp, faThumbsUp, faThumbsDown)

/* create the app instance */
const app = createApp(App)

/* add font awesome icon component */
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')